import { j as t } from "./jsx-runtime-D0AQ1Mn7.js";
import { c as s, g as c } from "./utils-CJ9afRe1.js";
import * as o from "react";
import C from "react";
import "./Accordion-DrU-sWJL.js";
import "./Avatar-al9EpjB_.js";
import "./Badge-QBVLNL8O.js";
import "./Button-CAmI0_H8.js";
import "./DropdownMenu-CRQiwey-.js";
import { I as i } from "./Icon-CtH7buGH.js";
import "./Input-CyIITBNc.js";
import "./Label-C4UDFZ-F.js";
import { T as p } from "./Text-CwTWgi3Y.js";
import "./Tooltip-B25WPLZY.js";
import { S as k } from "./Stack-B4QZt_R0.js";
import "./Switch-CXlkMKPd.js";
import "./Toast-4AphDOJm.js";
import { T as v } from "./Toggle-D-drA526.js";
import { c as H } from "./index-CYs0Zmg8.js";
const x = ({ children: a, className: e }) => /* @__PURE__ */ t.jsx(
  "span",
  {
    className: s(
      "border-l border-gray-neutral-60 text-black-primary text-xs first:border-0 px-3 truncate",
      e
    ),
    children: a
  }
);
x.displayName = "LoanSummaryHeaderItem";
const U = ({
  className: a,
  children: e
}) => {
  const r = c(e, "LoanSummaryHeaderItem") || [];
  return /* @__PURE__ */ t.jsx(
    "div",
    {
      className: s(
        "flex items-center bg-black-10 rounded-md border border-gray-neutral-20 py-2 w-fit",
        a
      ),
      children: r
    }
  );
}, X = Object.assign(U, { Item: x }), b = o.forwardRef(({ className: a, ...e }, r) => /* @__PURE__ */ t.jsx(
  "tbody",
  {
    ref: r,
    className: s("[&_tr:last-child]:border-0", a),
    ...e
  }
));
b.displayName = "TableBody";
const u = o.forwardRef(({ className: a, ...e }, r) => /* @__PURE__ */ t.jsx(
  "caption",
  {
    ref: r,
    className: s("mt-4 text-sm text-muted-foreground", a),
    ...e
  }
));
u.displayName = "TableCaption";
const f = o.forwardRef(({ className: a, ...e }, r) => /* @__PURE__ */ t.jsx(
  "td",
  {
    ref: r,
    className: s("p-4 align-middle [&:has([role=checkbox])]:pr-0", a),
    ...e
  }
));
f.displayName = "TableCell";
const y = o.forwardRef(({ className: a, ...e }, r) => /* @__PURE__ */ t.jsx(
  "tfoot",
  {
    ref: r,
    className: s("bg-primary font-medium text-primary-foreground", a),
    ...e
  }
));
y.displayName = "TableFooter";
const g = o.forwardRef(({ className: a, ...e }, r) => /* @__PURE__ */ t.jsx(
  "th",
  {
    ref: r,
    className: s(
      "px-4 py-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 text-xs text-black-primary",
      a
    ),
    ...e
  }
));
g.displayName = "TableHead";
const N = C.forwardRef(({ className: a, ...e }, r) => /* @__PURE__ */ t.jsx(
  "thead",
  {
    ref: r,
    className: s(
      "[&_tr]:border-b bg-gray-table-header min-h-[48px]",
      a
    ),
    ...e
  }
));
N.displayName = "TableHeader";
const T = o.forwardRef(({ className: a, ...e }, r) => /* @__PURE__ */ t.jsx(
  "tr",
  {
    ref: r,
    className: s(
      "border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
      a
    ),
    ...e
  }
));
T.displayName = "TableRow";
const h = o.forwardRef(({ className: a, ...e }, r) => /* @__PURE__ */ t.jsx("div", { className: "w-full overflow-auto", children: /* @__PURE__ */ t.jsx(
  "table",
  {
    ref: r,
    className: s("w-full caption-bottom text-sm", a),
    ...e
  }
) }));
h.displayName = "Table";
const Y = Object.assign(h, {
  Header: N,
  Body: b,
  Footer: y,
  Row: T,
  Head: g,
  Cell: f,
  Caption: u
}), L = H("border rounded-md", {
  variants: {
    size: {
      md: "h-10",
      sm: "h-8",
      xmd: "h-9",
      lg: "h-11"
    }
  },
  defaultVariants: {
    size: "md"
  }
}), S = ({
  children: a,
  size: e,
  className: r = ""
}) => {
  const l = o.Children.map(a, (m, d) => {
    if (o.isValidElement(m)) {
      let n = d === 0 ? "rounded-r-none" : "rounded-none border-l";
      return n = o.Children.count(a) === d + 1 ? "rounded-l-none border-l" : n, o.cloneElement(m, {
        size: e,
        className: `${n} h-full`
      });
    }
    return m;
  });
  return /* @__PURE__ */ t.jsx(k, { row: !0, className: s(L({ size: e, className: r })), children: l });
}, j = v;
j.displayName = "Toggle";
S.Toggle = j;
const w = ({ className: a, children: e }) => /* @__PURE__ */ t.jsx(
  p,
  {
    className: s(
      "text-gray-neutral-70 text-sm mt-1 leading-normal",
      a
    ),
    children: e
  }
);
w.displayName = "UserTypeCardDescription";
const R = ({ className: a, children: e }) => /* @__PURE__ */ t.jsx(
  p,
  {
    className: s(
      "text-black-primary text-md font-medium leading-normal",
      a
    ),
    children: e
  }
);
R.displayName = "UserTypeCardTitle";
const I = ({
  className: a,
  children: e,
  type: r = "lender",
  selected: l
}) => {
  const m = c(e, "UserTypeCardTitle"), d = c(
    e,
    "UserTypeCardDescription"
  );
  return /* @__PURE__ */ t.jsxs(
    "div",
    {
      className: s(
        "flex px-4 py-5 bg-white-neutral border border-gray-neutral-80 max-w-[480px] w-[480px] rounded-md",
        a,
        { "bg-blue-10": l }
      ),
      children: [
        r === "lender" ? /* @__PURE__ */ t.jsx(i, { name: "Lender" }) : /* @__PURE__ */ t.jsx(i, { name: "Borrower" }),
        /* @__PURE__ */ t.jsxs("div", { className: "ml-4 max-w-[300px]", children: [
          m,
          d
        ] }),
        l && /* @__PURE__ */ t.jsx(
          i,
          {
            name: "Check",
            strokeWidth: 1.5,
            width: 28,
            height: 28,
            className: "bg-blue-100 text-white-neutral rounded-full m-auto mr-0"
          }
        )
      ]
    }
  );
}, Z = Object.assign(I, {
  Title: R,
  Description: w
});
export {
  X as L,
  Y as T,
  Z as U,
  S as a
};
