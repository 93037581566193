import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import { A as I } from "./Accordion-DrU-sWJL.js";
import { A as y } from "./Avatar-al9EpjB_.js";
import { B as O } from "./Badge-QBVLNL8O.js";
import { B as F } from "./Button-CAmI0_H8.js";
import "./DropdownMenu-CRQiwey-.js";
import { I as h } from "./Icon-CtH7buGH.js";
import { I as M } from "./Input-CyIITBNc.js";
import { L as ke } from "./Label-C4UDFZ-F.js";
import { T as n } from "./Text-CwTWgi3Y.js";
import { T as g } from "./Tooltip-B25WPLZY.js";
import Y, { useCallback as j, useEffect as ce, useMemo as De, useRef as Ae, useState as X, forwardRef as Oe } from "react";
import { S as T } from "./Stack-B4QZt_R0.js";
import "./Switch-CXlkMKPd.js";
import "./Toast-4AphDOJm.js";
import "./Toggle-D-drA526.js";
import { A as ne } from "./AlertDialog.component-C7J7gpua.js";
import { C as P } from "./Checkbox.component-Cc7UYWRk.js";
import { D as he } from "./Drawer.component-CbbG_sic.js";
import { S as K } from "./Separator.component-CUuwnhk-.js";
import { u as me } from "./useMediaQuery-BLIs2rKV.js";
import { b as oe, a as de, F as Be } from "./FileIcon.component-vbYjyvoz.js";
import "./Breadcrumbs.component-BrfVMNto.js";
import "./ClientFolder-Bmow2wjH.js";
import "./DocumentCard-BapFF9gL.js";
import "./UserTypeCard-CxIDlWQ3.js";
import { A as Me } from "./AddFilesToUserConfirmation.component-Yh6wv-r3.js";
import { g as Re, a as xe, u as ze, b as Q, c as Pe, P as Ge, M as Ue, T as Ve, D as He, d as We } from "./core.esm-Cl0Ws55O.js";
import { E as Qe } from "./ElementDragOverlay.component-BHTa-AYH.js";
import { c as p } from "./utils-CJ9afRe1.js";
import { S as R } from "./Skeleton-B-D03H8u.js";
import { H as k } from "./HighlightText.component-C7C6qWaB.js";
import { L as ue } from "./LoanReviewStatusBadge.component-DSmcFeKn.js";
import { F as Xe, u as ge } from "./FilesDropContainer.component-BawaiUsB.js";
import { L as Ye, P as Ke } from "./PickLoanToMoveToConfirmation.component-CHkNSewZ.js";
import { D as C } from "./Dialog.component-fr4P9TXE.js";
import { P as Je } from "./Popover.component-B54m1MJr.js";
import { C as Ze } from "./CopyButton.component-Dyzp0vhQ.js";
import { S as H } from "./ScreenSizeDropdownMenu.component-D98neVYV.js";
import { S as $e, g as qe, a as es } from "./ShoeBoxFileRow.utils-BdNTAWFz.js";
import { A as ss } from "./ActionAlertDialog.component-DVgv06Iq.js";
import { R as ls } from "./RenameAlertDialog.component-DeA5RXcB.js";
const fe = (s, l) => l.includes(s.ownerUserId), as = (s, l) => !l || l.length === 0 ? s : s.filter(
  (a) => a.files.some((i) => l.includes(oe(de(i.documentName))))
).map((a) => ({
  ...a,
  files: a.files.filter((i) => l.includes(oe(de(i.documentName))))
})), Fe = Y.createContext({
  checkedFiles: [],
  totalCheckedFiles: 0,
  displayView: "GRID",
  team: [],
  onUnmount: () => {
  },
  loansFilterQuery: "",
  hideLoanFilter: !1,
  teamUserIds: [],
  onSetLoansFilterQuery: () => {
  },
  filterTypes: [],
  droppedFiles: [],
  onCheckTeamUser: () => {
  },
  rejectedFiles: [],
  droppedShoeBoxFiles: [],
  secureUploadPageLink: "",
  loans: [],
  activeMobileTab: "SHOEBOX",
  moveToLoanShoeBoxFiles: [],
  shoeBoxFileToRename: null,
  folders: [],
  stagedFilesForDeletion: [],
  addAllLoan: null,
  totalFiles: 0,
  me: {
    id: "",
    name: "",
    role: "",
    email: "",
    filesCount: 0,
    avatarUrl: "",
    givenName: "",
    initials: ""
  },
  pickLenderLoan: {
    loan: null,
    files: []
  },
  teamIdsSorted: [],
  selectedLoansFilter: "MY_LOANS",
  loadingShoeBoxFiles: !0,
  loadingLoans: !0,
  checkedMoveFilesIds: [],
  checkFilesFromOrToFile: () => {
  },
  onClose: () => {
  },
  openFileDoubleClick: () => {
  },
  goToLoanCreatePage: () => {
  },
  setMoveAllFilesIds: () => {
  },
  renameShoeBoxFile: () => {
  },
  goToLoan: () => {
  },
  moveShoeBoxFilesToLoan: () => {
  },
  setAddAllLoan: () => {
  },
  setPickLenderLoan: () => {
  },
  setMoveToLoanShoeBoxFiles: () => {
  },
  setDroppedShoeBoxFiles: () => {
  },
  openFileInNewTab: () => {
  },
  isLoanLoading: () => !1,
  uploadFilesToShoeBox: () => {
  },
  setLoansFilter: () => {
  },
  setFilterTypes: () => {
  },
  setDisplayView: () => {
  },
  unCheckFile: () => {
  },
  setActiveMobileTab: () => {
  },
  onDownloadShoeBoxFiles: () => {
  },
  setRejectedFiles: () => {
  },
  checkFile: () => {
  },
  setStagedFilesForDeletion: () => {
  },
  setRenameShoeBoxFile: () => {
  },
  unCheckAllFiles: () => {
  },
  isFileChecked: () => !1,
  addSelectedFilesToLoan: () => {
  },
  addAllFilesToLoan: () => {
  },
  uploadFilesToLoan: () => {
  },
  deleteShoeBoxItems: () => {
  },
  cancelUploadingFile: () => {
  }
}), o = {
  SET_LOANS_FILTER_QUERY: "SET_LOANS_FILTER_QUERY",
  TOGGLE_TEAM_USER: "TOGGLE_TEAM_USER",
  SET_TEAM_USER_IDS: "SET_TEAM_USER_IDS",
  ADD_FILE: "ADD_FILE",
  SET_DISPLAY_VIEW: "SET_DISPLAY_VIEW",
  SET_LOANS_FILTER: "SET_LOANS_FILTER",
  SET_TEAM_IDS_SORTED: "SET_TEAM_IDS_SORTED",
  REMOVE_FILE: "REMOVE_FILE",
  SET_LOAN_LOADING_STATE: "SET_LOAN_LOADING_STATE",
  SET_FILTER_TYPES: "SET_FILTER_TYPES",
  SET_PICK_LENDER_LOAN: "SET_PICK_LENDER_LOAN",
  SET_ADD_ALL_LOAN: "SET_ADD_ALL_LOAN",
  CLEAR_FILES: "CLEAR_FILES",
  SET_CHECKED_MOVE_FILES_IDS: "SET_CHECKED_MOVE_FILES_IDS",
  SET_RENAME_SHOEBOX_FILE: "SET_RENAME_SHOEBOX_FILE",
  SET_STAGED_FILES_FOR_DELETION: "SET_STAGED_FILES_FOR_DELETION",
  SET_DROPPED_FILES: "SET_DROPPED_FILES",
  SET_REJECTED_FILES: "SET_REJECTED_FILES",
  SET_ACTIVE_MOBILE_TAB: "SET_ACTIVE_MOBILE_TAB",
  SET_MOVE_TO_LOAN_SHOEBOX_FILES: "SET_MOVE_TO_LOAN_SHOEBOX_FILES",
  SET_DROPPED_SHOEBOX_FILES: "SET_DROPPED_SHOEBOX_FILES"
}, ts = {
  loansFilterQuery: "",
  filterTypes: [],
  teamIdsSorted: [],
  checkedFiles: [],
  displayView: "GRID",
  stagedFilesForDeletion: [],
  selectedLoansFilter: "MY_LOANS",
  loansLoadingState: {},
  addAllLoan: null,
  pickLenderLoan: {
    loan: null,
    files: []
  },
  teamUserIds: [],
  droppedFiles: [],
  activeMobileTab: "SHOEBOX",
  rejectedFiles: [],
  droppedShoeBoxFiles: [],
  moveToLoanShoeBoxFiles: [],
  shoeBoxFileToRename: null,
  checkedMoveFilesIds: []
}, is = (s, l) => {
  switch (l.type) {
    case o.ADD_FILE:
      return {
        ...s,
        checkedFiles: [...s.checkedFiles, l.payload]
      };
    case o.REMOVE_FILE:
      return {
        ...s,
        checkedFiles: s.checkedFiles.filter(
          (a) => a.id !== l.payload.id
        )
      };
    case o.TOGGLE_TEAM_USER:
      return {
        ...s,
        teamUserIds: s.teamUserIds.includes(l.payload) ? s.teamUserIds.filter((a) => a !== l.payload) : [...s.teamUserIds, l.payload]
      };
    case o.SET_FILTER_TYPES:
      return {
        ...s,
        filterTypes: l.payload
      };
    case o.CLEAR_FILES:
      return {
        ...s,
        checkedFiles: [],
        droppedFiles: [],
        droppedShoeBoxFiles: [],
        moveToLoanShoeBoxFiles: []
      };
    case o.SET_TEAM_USER_IDS:
      return {
        ...s,
        teamUserIds: l.payload
      };
    case o.SET_LOANS_FILTER:
      return {
        ...s,
        selectedLoansFilter: l.payload
      };
    case o.SET_DISPLAY_VIEW:
      return {
        ...s,
        displayView: l.payload
      };
    case o.SET_DROPPED_SHOEBOX_FILES:
      return {
        ...s,
        droppedShoeBoxFiles: l.payload
      };
    case o.SET_CHECKED_MOVE_FILES_IDS:
      return {
        ...s,
        checkedMoveFilesIds: l.payload
      };
    case o.SET_STAGED_FILES_FOR_DELETION:
      return {
        ...s,
        stagedFilesForDeletion: l.payload
      };
    case o.SET_REJECTED_FILES:
      return {
        ...s,
        rejectedFiles: l.payload
      };
    case o.SET_ACTIVE_MOBILE_TAB:
      return {
        ...s,
        activeMobileTab: l.payload
      };
    case o.SET_DROPPED_FILES:
      return {
        ...s,
        droppedFiles: l.payload
      };
    case o.SET_LOANS_FILTER_QUERY:
      return {
        ...s,
        loansFilterQuery: l.payload
      };
    case o.SET_MOVE_TO_LOAN_SHOEBOX_FILES:
      return {
        ...s,
        moveToLoanShoeBoxFiles: l.payload
      };
    case o.SET_ADD_ALL_LOAN:
      return {
        ...s,
        addAllLoan: l.payload
      };
    case o.SET_TEAM_IDS_SORTED:
      return {
        ...s,
        teamIdsSorted: l.payload
      };
    case o.SET_PICK_LENDER_LOAN:
      return {
        ...s,
        pickLenderLoan: l.payload
      };
    case o.SET_RENAME_SHOEBOX_FILE:
      return {
        ...s,
        shoeBoxFileToRename: l.payload
      };
    case o.SET_LOAN_LOADING_STATE:
      return {
        ...s,
        loansLoadingState: {
          ...s.loansLoadingState,
          [l.payload.id]: l.payload.state
        }
      };
    default:
      return s;
  }
}, jl = (s) => {
  const [l, a] = Y.useReducer(is, ts), i = j((t) => {
    a({ type: o.ADD_FILE, payload: t });
  }, []), r = (t) => {
    a({ type: o.REMOVE_FILE, payload: t });
  }, x = () => {
    a({ type: o.CLEAR_FILES });
  }, m = (t) => {
    a({ type: o.SET_DISPLAY_VIEW, payload: t });
  }, u = (t) => {
    a({ type: o.SET_LOANS_FILTER, payload: t });
  }, d = (t) => {
    a({ type: o.SET_FILTER_TYPES, payload: t });
  }, L = j(async (t) => {
    a({
      type: o.SET_LOAN_LOADING_STATE,
      payload: {
        id: t.id,
        state: "ADD_ALL"
      }
    });
    try {
      const c = s.folders.reduce((f, b) => f.concat(b.files, b.subFolders.map((A) => A.files).flat()), []).filter((f) => l.checkedMoveFilesIds.includes(f.id));
      t.users.some((f) => f.id === s.me.id) ? (await s.onMoveShoeBoxFilesToLoan(t, s.me.id, c), a({ type: o.CLEAR_FILES })) : a({
        type: o.SET_PICK_LENDER_LOAN,
        payload: {
          loan: t,
          files: c
        }
      });
    } catch (c) {
      console.error(c);
    } finally {
      a({
        type: o.SET_ADD_ALL_LOAN,
        payload: null
      }), a({
        type: o.SET_LOAN_LOADING_STATE,
        payload: {
          id: t.id,
          state: "NONE"
        }
      });
    }
  }, [s, l.checkedMoveFilesIds]), w = j(async (t, c, f) => {
    a({
      type: o.SET_LOAN_LOADING_STATE,
      payload: {
        id: t.id,
        state: "ADD_SELECTED"
      }
    });
    try {
      await s.onMoveShoeBoxFilesToLoan(t, f, c), a({ type: o.CLEAR_FILES });
    } catch (b) {
      console.error(b);
    } finally {
      a({
        type: o.SET_LOAN_LOADING_STATE,
        payload: {
          id: t.id,
          state: "NONE"
        }
      });
    }
  }, [s]), S = j(async (t, c, f) => {
    a({
      type: o.SET_LOAN_LOADING_STATE,
      payload: {
        id: t.id,
        state: "ADD_SELECTED"
      }
    });
    try {
      c === s.me.id && !t.users.some((b) => b.id === c) && await s.onAddMeToLoan(t.id), await s.onMoveShoeBoxFilesToLoan(t, c, f);
    } catch (b) {
      console.error(b);
    } finally {
      a({ type: o.CLEAR_FILES }), a({
        type: o.SET_PICK_LENDER_LOAN,
        payload: {
          loan: null,
          files: []
        }
      }), a({
        type: o.SET_LOAN_LOADING_STATE,
        payload: {
          id: t.id,
          state: "NONE"
        }
      });
    }
  }, [s]), v = j(async (t, c = []) => {
    t != null && t.users.some((f) => f.id === s.me.id) ? await S(t, s.me.id, c) : a({
      type: o.SET_PICK_LENDER_LOAN,
      payload: {
        loan: t,
        files: c
      }
    });
  }, [S, s.me.id]), _ = j(
    async (t, c) => {
      t != null && t.users.some((f) => f.id === s.me.id) ? await w(t, c, s.me.id) : (a({
        type: o.SET_DROPPED_SHOEBOX_FILES,
        payload: c
      }), await v(t, c));
    },
    [w, v, s.me.id]
  ), B = j(
    (t) => {
      _(t, l.checkedFiles);
    },
    [l.checkedFiles, _]
  ), G = j(
    (t, c) => c === l.loansLoadingState[t],
    [l.loansLoadingState]
  ), U = j(
    (t, c) => {
      t.users.some((f) => f.id === s.me.id) || (a({ type: o.SET_DROPPED_FILES, payload: c }), v(t));
    },
    [v, s.me.id]
  ), D = j((t) => {
    const c = s.folders.reduce((f, b) => {
      const A = b.files.map((z) => z.id), N = b.subFolders.map((z) => z.files.map((Ie) => Ie.id)).flat();
      return f.concat(
        A,
        N
      );
    }, []);
    a({
      type: o.SET_CHECKED_MOVE_FILES_IDS,
      payload: c
    }), a({ type: o.SET_ADD_ALL_LOAN, payload: t });
  }, [s.folders]), W = (t) => {
    a({ type: o.SET_MOVE_TO_LOAN_SHOEBOX_FILES, payload: t });
  }, V = j(async (t, c) => {
    await s.onAddFilesToShoeBox(t, c), a({ type: o.SET_REJECTED_FILES, payload: c });
  }, [s]), Z = j((t) => {
    s.onCancelUploadingFile(t);
  }, [s]), $ = j(async (t) => {
    await s.onDeleteShoeBoxFiles(t), a({ type: o.CLEAR_FILES });
  }, [s]), q = j(async (t, c) => {
    s.onRenameShoeBoxFile(t, c), a({ type: o.SET_RENAME_SHOEBOX_FILE, payload: null });
  }, [s]), ve = (t) => {
    a({ type: o.SET_ACTIVE_MOBILE_TAB, payload: t });
  }, Ne = (t) => {
    a({ type: o.SET_RENAME_SHOEBOX_FILE, payload: t });
  }, we = (t) => {
    a({ type: o.SET_CHECKED_MOVE_FILES_IDS, payload: t });
  }, _e = (t) => {
    a({ type: o.SET_STAGED_FILES_FOR_DELETION, payload: t });
  }, ee = j((t) => {
    a({ type: o.SET_LOANS_FILTER_QUERY, payload: t });
  }, []), se = j((t) => {
    a({ type: o.TOGGLE_TEAM_USER, payload: t });
    const c = l.teamUserIds.includes(t) ? l.teamUserIds.filter((f) => f !== t) : [...l.teamUserIds, t];
    s.onTeamFilterChange(c);
  }, [l.teamUserIds]), le = j(() => {
    const t = s.team.map((c) => c.id).sort((c, f) => {
      const b = l.teamUserIds.includes(c), A = l.teamUserIds.includes(f);
      return b && !A ? -1 : !b && A ? 1 : 0;
    });
    a({ type: o.SET_TEAM_IDS_SORTED, payload: t });
  }, [s.team, l.teamUserIds]);
  ce(() => {
    a({ type: o.SET_TEAM_USER_IDS, payload: s.selectedTeamUserIds });
  }, [s.selectedTeamUserIds]);
  const ae = j((t) => {
    const c = l.checkedFiles[l.checkedFiles.length - 1];
    if (!c)
      i(t);
    else {
      const f = s.folders.map((N) => N.files).flat().findIndex((N) => N.id === c.id), b = s.folders.map((N) => N.files).flat().findIndex((N) => N.id === t.id);
      s.folders.map((N) => N.files).flat().slice(
        Math.min(f, b),
        Math.max(f, b) + 1
      ).forEach(
        (N) => {
          l.checkedFiles.some((z) => z.id === N.id) || i(N);
        }
      );
    }
  }, [l.checkedFiles, s.folders, i]), te = as(s.folders.map((t) => ({
    ...t,
    files: t.files.filter((c) => !l.droppedShoeBoxFiles.some((f) => f.id === c.id))
  })), l.filterTypes), ie = j((t) => {
    var c;
    (c = s.onDownloadShoeBoxFiles) == null || c.call(s, t);
  }, [s.onDownloadShoeBoxFiles]), Ce = De(
    () => ({
      onUnmount: le,
      checkFile: i,
      unCheckFile: r,
      unCheckAllFiles: x,
      setLoansFilter: u,
      setFilterTypes: d,
      setDisplayView: m,
      addSelectedFilesToLoan: B,
      addAllFilesToLoan: L,
      isLoanLoading: G,
      uploadFilesToLoan: U,
      setAddAllLoan: D,
      setDroppedShoeBoxFiles: _,
      setMoveToLoanShoeBoxFiles: W,
      moveShoeBoxFilesToLoan: S,
      setPickLenderLoan: v,
      uploadFilesToShoeBox: V,
      cancelUploadingFile: Z,
      goToLoan: s.onGoToLoan,
      setStagedFilesForDeletion: _e,
      openFileInNewTab: s.onOpenShoeBoxFileInNewTab,
      deleteShoeBoxItems: $,
      renameShoeBoxFile: q,
      setRenameShoeBoxFile: Ne,
      onCheckTeamUser: se,
      onDownloadShoeBoxFiles: ie,
      goToLoanCreatePage: s.onGoToLoanCreatePage,
      setMoveAllFilesIds: we,
      onSetLoansFilterQuery: ee,
      setRejectedFiles: (t) => a({ type: o.SET_REJECTED_FILES, payload: t }),
      isFileChecked: (t) => l.checkedFiles.some((c) => c.id === t.id),
      openFileDoubleClick: s.onOpenFileDoubleClick,
      checkFilesFromOrToFile: ae,
      setActiveMobileTab: ve,
      checkedFiles: l.checkedFiles,
      totalCheckedFiles: l.checkedFiles.length,
      displayView: l.displayView,
      selectedLoansFilter: l.selectedLoansFilter,
      folders: te,
      loans: s.loans,
      filterTypes: l.filterTypes,
      moveToLoanShoeBoxFiles: l.moveToLoanShoeBoxFiles,
      team: s.team,
      addAllLoan: l.addAllLoan,
      me: s.me,
      rejectedFiles: l.rejectedFiles,
      stagedFilesForDeletion: l.stagedFilesForDeletion,
      droppedFiles: l.droppedFiles,
      pickLenderLoan: l.pickLenderLoan,
      shoeBoxFileToRename: l.shoeBoxFileToRename,
      loadingShoeBoxFiles: s.loadingShoeBoxFiles,
      loadingLoans: s.loadingLoans,
      checkedMoveFilesIds: l.checkedMoveFilesIds,
      droppedShoeBoxFiles: l.droppedShoeBoxFiles,
      activeMobileTab: l.activeMobileTab,
      totalFiles: s.totalItems,
      teamIdsSorted: l.teamIdsSorted,
      teamUserIds: l.teamUserIds,
      loansFilterQuery: l.loansFilterQuery,
      hideLoanFilter: s.hideLoanFilter ?? !1,
      secureUploadPageLink: s.secureUploadPageLink,
      onClose: s.onClose
    }),
    [le, i, B, L, G, U, D, _, S, v, V, Z, s.onGoToLoan, s.onOpenShoeBoxFileInNewTab, s.onGoToLoanCreatePage, s.onOpenFileDoubleClick, s.loans, s.team, s.me, s.loadingShoeBoxFiles, s.loadingLoans, s.totalItems, s.hideLoanFilter, s.secureUploadPageLink, s.onClose, $, q, se, ie, ee, ae, l.checkedFiles, l.displayView, l.selectedLoansFilter, l.filterTypes, l.moveToLoanShoeBoxFiles, l.addAllLoan, l.rejectedFiles, l.stagedFilesForDeletion, l.droppedFiles, l.pickLenderLoan, l.shoeBoxFileToRename, l.checkedMoveFilesIds, l.droppedShoeBoxFiles, l.activeMobileTab, l.teamIdsSorted, l.teamUserIds, l.loansFilterQuery, te]
  );
  return /* @__PURE__ */ e.jsx(Fe.Provider, { value: Ce, children: s.children });
}, E = () => {
  const s = Y.useContext(Fe);
  if (s === void 0)
    throw new Error(
      "useGlobalShoeBox must be used within a GlobalShoeBoxProvider"
    );
  return s;
}, ns = () => {
  var x, m;
  const s = E(), a = me("(min-width: 768px)") ? ne : he, i = s.folders.reduce((u, d) => (u.push(d.key), d.subFolders.forEach((L) => u.push(L.key)), u), []), r = s.folders.reduce((u, d) => (u.push(...d.files.map((L) => L.id)), d.subFolders.forEach((L) => {
    u.push(...L.files.map((w) => w.id));
  }), u), []);
  return /* @__PURE__ */ e.jsxs(
    a,
    {
      onOpenChange: (u) => !u && s.setAddAllLoan(null),
      open: !!s.addAllLoan,
      children: [
        /* @__PURE__ */ e.jsx(a.Overlay, {}),
        /* @__PURE__ */ e.jsxs(
          a.Content,
          {
            className: "pt-8 max-w-md",
            children: [
              /* @__PURE__ */ e.jsxs(a.Header, { className: "px-8 items-center flex flex-col justify-center", children: [
                /* @__PURE__ */ e.jsx(h, { name: "BigExclamation", className: "text-black-10" }),
                /* @__PURE__ */ e.jsxs(n, { center: !0, as: "div", className: "pt-6 sm:pt-8", children: [
                  "Are you sure you want to add all items to ",
                  (x = s.addAllLoan) == null ? void 0 : x.shortCode,
                  " ",
                  (m = s.addAllLoan) == null ? void 0 : m.projectName,
                  " ?"
                ] })
              ] }),
              /* @__PURE__ */ e.jsx("div", { className: "px-2 sm:px-8 scrollbar-stable overflow-auto", children: /* @__PURE__ */ e.jsxs("div", { className: "max-h-72 flex gap-3 flex-col h-fit rounded-md border border-1 py-3", children: [
                /* @__PURE__ */ e.jsxs(ke, { className: "flex items-center gap-2 px-3", children: [
                  /* @__PURE__ */ e.jsx(
                    P,
                    {
                      onCheckedChange: (u) => {
                        u ? s.setMoveAllFilesIds(r) : s.setMoveAllFilesIds([]);
                      },
                      checked: s.checkedMoveFilesIds.length === 0 ? !1 : s.checkedMoveFilesIds.length === s.totalFiles ? !0 : "indeterminate"
                    }
                  ),
                  /* @__PURE__ */ e.jsx(n, { children: "Select all" })
                ] }),
                /* @__PURE__ */ e.jsx(
                  I,
                  {
                    className: "overflow-y-auto scrollbar-stable mx-3 border border-b-1 border-t-1 border-x-0",
                    defaultValue: i,
                    type: "multiple",
                    children: s.folders.filter((u) => u.files.length || u.subFolders.some((d) => d.files.length)).map((u) => /* @__PURE__ */ e.jsx(
                      Se,
                      {
                        folder: u,
                        setMoveAllFilesIds: s.setMoveAllFilesIds,
                        checkedMoveFilesIds: s.checkedMoveFilesIds
                      },
                      u.key
                    ))
                  }
                )
              ] }) }),
              /* @__PURE__ */ e.jsx(K, {}),
              /* @__PURE__ */ e.jsxs(ne.Footer, { children: [
                /* @__PURE__ */ e.jsx(
                  F,
                  {
                    onClick: () => s.setAddAllLoan(null),
                    className: "w-24",
                    variant: "outline",
                    children: "Cancel"
                  }
                ),
                /* @__PURE__ */ e.jsx(
                  F,
                  {
                    className: "w-24",
                    disabled: s.checkedMoveFilesIds.length === 0,
                    onClick: () => s.addAllLoan && s.addAllFilesToLoan(s.addAllLoan),
                    children: "Add all"
                  }
                )
              ] })
            ]
          }
        )
      ]
    }
  );
}, Se = (s) => /* @__PURE__ */ e.jsxs(
  I.Item,
  {
    value: s.folder.key,
    title: s.folder.name,
    children: [
      /* @__PURE__ */ e.jsx(I.Trigger, { asChild: !0, className: "cursor-pointer", children: /* @__PURE__ */ e.jsxs(T, { row: !0, space: "sm", children: [
        /* @__PURE__ */ e.jsx(
          O,
          {
            variant: "warning",
            className: "p-0 inline-flex w-5 h-5 items-center justify-center",
            children: s.folder.filesCount
          }
        ),
        /* @__PURE__ */ e.jsx(n, { size: "sm", truncate: !0, children: s.folder.senderName ?? s.folder.senderEmailAddress })
      ] }, s.folder.key) }),
      /* @__PURE__ */ e.jsxs(I.Content, { className: "pl-2", children: [
        s.folder.subFolders.map((l) => /* @__PURE__ */ e.jsx(
          Se,
          {
            folder: l,
            setMoveAllFilesIds: s.setMoveAllFilesIds,
            checkedMoveFilesIds: s.checkedMoveFilesIds
          },
          l.key
        )),
        /* @__PURE__ */ e.jsx(T, { space: "md", children: s.folder.files.map((l) => /* @__PURE__ */ e.jsxs(
          T,
          {
            row: !0,
            space: "sm",
            className: "items-center",
            children: [
              /* @__PURE__ */ e.jsx(
                P,
                {
                  onCheckedChange: (a) => {
                    s.setMoveAllFilesIds(
                      a ? [...s.checkedMoveFilesIds, l.id] : s.checkedMoveFilesIds.filter(
                        (i) => i !== l.id
                      )
                    );
                  },
                  checked: s.checkedMoveFilesIds.includes(l.id)
                }
              ),
              /* @__PURE__ */ e.jsx(Be, { fileName: l.documentName }),
              /* @__PURE__ */ e.jsx(n, { size: "sm", truncate: !0, children: l.title })
            ]
          },
          l.id
        )) })
      ] })
    ]
  },
  s.folder.key
), os = () => {
  const s = E();
  return /* @__PURE__ */ e.jsx(
    Me,
    {
      open: !!s.pickLenderLoan.loan,
      onOpenChange: (l) => {
        l || (s.setPickLenderLoan(null, []), s.setDroppedShoeBoxFiles(null, []));
      },
      loan: s.pickLenderLoan.loan,
      items: s.pickLenderLoan.files,
      me: s.me,
      onConfirm: s.moveShoeBoxFilesToLoan
    }
  );
};
function ds(s, l, a) {
  const i = {
    ...s
  };
  return l.top + s.y <= a.top ? i.y = a.top - l.top : l.bottom + s.y >= a.top + a.height && (i.y = a.top + a.height - l.bottom), l.left + s.x <= a.left ? i.x = a.left - l.left : l.right + s.x >= a.left + a.width && (i.x = a.left + a.width - l.right), i;
}
const rs = (s) => {
  let {
    transform: l,
    draggingNodeRect: a,
    windowRect: i
  } = s;
  return !a || !i ? l : ds(l, a, i);
}, cs = (s) => {
  let {
    activatorEvent: l,
    draggingNodeRect: a,
    transform: i
  } = s;
  if (a && l) {
    const r = Re(l);
    if (!r)
      return i;
    const x = r.x - a.left, m = r.y - a.top;
    return {
      ...i,
      x: i.x + x - a.width / 2,
      y: i.y + m - a.height / 2
    };
  }
  return i;
}, hs = () => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
  /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-48" }) }),
  /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-48" }) }),
  /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-48" }) })
] }), ms = (s) => {
  const l = E(), { setNodeRef: a, isOver: i } = xe({
    id: s.loan.id,
    data: s.loan
  });
  return /* @__PURE__ */ e.jsxs(
    "article",
    {
      ref: a,
      "data-ui": i ? "active" : "",
      className: "min-h-48 bg-white rounded-md border data-active:border-blue-100 border-gray-neutral-80 overflow-hidden p-4 relative",
      children: [
        i && /* @__PURE__ */ e.jsx(
          n,
          {
            size: "sm",
            weight: "medium",
            as: "div",
            "data-ui": i ? "active" : "",
            className: "items-center justify-center flex p-4 opacity-0 data-active:opacity-100 transition-opacity will-change-auto absolute inset-0 z-10 bg-blue-50 bg-opacity-70 text-blue-100",
            children: /* @__PURE__ */ e.jsx("div", { className: "p-2 rounded-md bg-blue-50", children: "DROP FILES HERE" })
          }
        ),
        /* @__PURE__ */ e.jsxs("div", { children: [
          /* @__PURE__ */ e.jsx(
            n,
            {
              size: "xs",
              className: "rounded inline-flex px-1 text-gray-neutral-70 border border-gray-neutral-40",
              children: /* @__PURE__ */ e.jsx(k, { text: s.loan.shortCode, highlightText: String(s.highlightText) })
            }
          ),
          /* @__PURE__ */ e.jsxs(g, { children: [
            /* @__PURE__ */ e.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
              n,
              {
                as: "div",
                truncate: !0,
                size: "sm",
                weight: "medium",
                className: "mt-3",
                children: /* @__PURE__ */ e.jsx(k, { text: s.loan.projectName, highlightText: String(s.highlightText) })
              }
            ) }),
            /* @__PURE__ */ e.jsx(
              g.Content,
              {
                className: "w-60 break-all",
                align: "start",
                side: "bottom",
                alignOffset: 0,
                children: s.loan.projectName
              }
            )
          ] }),
          /* @__PURE__ */ e.jsxs(g, { children: [
            /* @__PURE__ */ e.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsxs(n, { as: "div", truncate: !0, size: "xs", variant: "secondary", className: "mt-2 overflow-hidden max-h-4 whitespace-nowrap", children: [
              /* @__PURE__ */ e.jsx("div", { dangerouslySetInnerHTML: { __html: s.loan.loanPurpose } }),
              " "
            ] }) }),
            /* @__PURE__ */ e.jsx(
              g.Content,
              {
                className: "w-60 break-all",
                align: "start",
                side: "bottom",
                alignOffset: 0,
                children: /* @__PURE__ */ e.jsx("div", { dangerouslySetInnerHTML: { __html: s.loan.loanPurpose } })
              }
            )
          ] })
        ] }),
        /* @__PURE__ */ e.jsxs("div", { className: "flex gap-3 mt-3 flex-1", children: [
          /* @__PURE__ */ e.jsxs(O, { variant: "secondary", className: "inline-flex gap-2 truncate", children: [
            /* @__PURE__ */ e.jsx(h, { name: "Bag", width: 15, height: 15 }),
            /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(k, { text: s.loan.loanType, highlightText: String(s.highlightText) }) })
          ] }),
          /* @__PURE__ */ e.jsx(ue, { status: s.loan.loanStatus.status, children: /* @__PURE__ */ e.jsx(k, { text: s.loan.loanStatus.label, highlightText: String(s.highlightText) }) })
        ] }),
        /* @__PURE__ */ e.jsxs("footer", { className: "flex gap-4 mt-6", children: [
          l.totalFiles > 0 && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
            /* @__PURE__ */ e.jsx(
              F,
              {
                loading: l.isLoanLoading(s.loan.id, "ADD_ALL"),
                onClick: () => l.setAddAllLoan(s.loan),
                variant: "outline",
                size: "sm",
                disabled: l.totalFiles === 0,
                className: "text-blue-100 bg-white truncate",
                children: "Add All"
              }
            ),
            /* @__PURE__ */ e.jsx(
              F,
              {
                disabled: l.checkedFiles.length === 0,
                loading: l.isLoanLoading(s.loan.id, "ADD_SELECTED"),
                onClick: () => l.addSelectedFilesToLoan(s.loan),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate",
                children: "Add Selected"
              }
            )
          ] }),
          /* @__PURE__ */ e.jsxs(g, { children: [
            /* @__PURE__ */ e.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsxs(
              F,
              {
                onClick: () => l.goToLoan(s.loan.id),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate",
                children: [
                  /* @__PURE__ */ e.jsx(h, { name: "Handbag", className: "shrink-0", width: 20, height: 20, strokeWidth: 1.5 }),
                  /* @__PURE__ */ e.jsx(
                    "span",
                    {
                      "data-ui": l.totalFiles === 0 ? "active" : "",
                      className: "hidden data-active:inline-block lg:data-active:inline-block",
                      children: "Go to Loan"
                    }
                  )
                ]
              }
            ) }),
            /* @__PURE__ */ e.jsx(g.Content, { align: "center", side: "bottom", alignOffset: 0, children: "Go to Loan" })
          ] })
        ] })
      ]
    },
    s.loan.id
  );
}, xs = (s) => {
  const l = E(), { setNodeRef: a, isOver: i } = xe({
    id: s.loan.id,
    data: s.loan
  }), r = l.folders.some((x) => x.files.some((m) => !m.uploading));
  return /* @__PURE__ */ e.jsxs(
    "article",
    {
      ref: a,
      "data-ui": i ? "active" : "",
      className: "min-h-16 bg-white gap-4 grid grid-cols-6 lg:flex lg:flex-row items-center rounded-md border data-active:border-blue-100 border-gray-neutral-80 overflow-hidden p-4 relative",
      children: [
        i && /* @__PURE__ */ e.jsx(
          n,
          {
            size: "sm",
            weight: "medium",
            as: "div",
            "data-ui": i ? "active" : "",
            className: "items-center justify-center z-10 flex p-4 opacity-0 data-active:opacity-100 transition-opacity will-change-auto absolute inset-0 bg-blue-50 bg-opacity-70 text-blue-100",
            children: /* @__PURE__ */ e.jsx("div", { className: "p-2 rounded-md bg-blue-50", children: "DROP FILES HERE" })
          }
        ),
        /* @__PURE__ */ e.jsx(n, { size: "sm", weight: "medium", className: "shrink-0 col-span-2", children: /* @__PURE__ */ e.jsx(k, { text: s.loan.shortCode, highlightText: String(s.highlightText) }) }),
        /* @__PURE__ */ e.jsx(
          n,
          {
            size: "sm",
            weight: "medium",
            className: "flex-1 order-4 lg:order-2 col-span-3",
            truncate: !0,
            children: /* @__PURE__ */ e.jsx(k, { text: s.loan.projectName, highlightText: String(s.highlightText) })
          }
        ),
        /* @__PURE__ */ e.jsxs("div", { className: "col-span-4 flex justify-end lg:justify-start  order-2 lg:order-3 items-center gap-4", children: [
          /* @__PURE__ */ e.jsxs(O, { variant: "secondary", className: "inline-flex gap-2 w-auto min-w-0 max-w-40", children: [
            /* @__PURE__ */ e.jsx(h, { name: "Bag", width: 15, height: 15, className: "shrink-0" }),
            /* @__PURE__ */ e.jsx("span", { className: "truncate", children: /* @__PURE__ */ e.jsx(k, { text: s.loan.loanType, highlightText: String(s.highlightText) }) })
          ] }),
          /* @__PURE__ */ e.jsx(
            ue,
            {
              status: s.loan.loanStatus.status,
              children: /* @__PURE__ */ e.jsx(k, { text: s.loan.loanStatus.label, highlightText: String(s.highlightText) })
            }
          )
        ] }),
        /* @__PURE__ */ e.jsxs("div", { className: "order-5 col-span-3 justify-end flex flex-row lg:flex items-center gap-2 lg:gap-4 sm:order-4", children: [
          l.totalFiles > 0 && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
            /* @__PURE__ */ e.jsx(
              F,
              {
                disabled: !r,
                loading: l.isLoanLoading(s.loan.id, "ADD_ALL"),
                onClick: () => l.setAddAllLoan(s.loan),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate",
                children: "Add All"
              }
            ),
            /* @__PURE__ */ e.jsx(
              F,
              {
                disabled: l.checkedFiles.length === 0,
                loading: l.isLoanLoading(s.loan.id, "ADD_SELECTED"),
                onClick: () => l.addSelectedFilesToLoan(s.loan),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate px-2",
                children: "Add Selected"
              }
            )
          ] }),
          /* @__PURE__ */ e.jsxs(g, { children: [
            /* @__PURE__ */ e.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsxs(
              F,
              {
                onClick: () => l.goToLoan(s.loan.id),
                variant: "outline",
                size: "sm",
                className: "text-blue-100 bg-white truncate flex items-center gap-1",
                children: [
                  /* @__PURE__ */ e.jsx(h, { name: "Handbag", className: "shrink-0", width: 20, height: 20, strokeWidth: 1.5 }),
                  /* @__PURE__ */ e.jsx(
                    "span",
                    {
                      "data-ui": l.totalFiles === 0 ? "active" : "",
                      className: "hidden data-active:inline-block lg:data-active:inline-block",
                      children: "Go to Loan"
                    }
                  )
                ]
              }
            ) }),
            /* @__PURE__ */ e.jsx(
              g.Content,
              {
                align: "start",
                side: "bottom",
                alignOffset: 0,
                children: "Go to Loan"
              }
            )
          ] })
        ] })
      ]
    },
    s.loan.id
  );
}, je = () => {
  const s = E();
  return /* @__PURE__ */ e.jsxs(
    M,
    {
      wrapClassName: "w-full bg-black-20 rounded-full h-8",
      value: s.loansFilterQuery,
      onChange: (l) => s.onSetLoansFilterQuery(l.target.value),
      placeholder: "Search loan, title, ID, type or status",
      variant: "sm",
      children: [
        /* @__PURE__ */ e.jsx(M.Prefix, { children: /* @__PURE__ */ e.jsx(h, { name: "Search", width: 18, height: 18, strokeWidth: 1.5 }) }),
        s.loansFilterQuery && /* @__PURE__ */ e.jsx(M.Suffix, { children: /* @__PURE__ */ e.jsx(
          F,
          {
            circle: !0,
            "data-hidden": !s.loansFilterQuery,
            onClick: () => s.onSetLoansFilterQuery(""),
            variant: "ghost",
            size: "sm",
            className: "px-1",
            children: /* @__PURE__ */ e.jsx(h, { name: "Cancel", width: 18, height: 18, strokeWidth: 1.5 })
          }
        ) })
      ]
    }
  );
}, us = "global-shoebox-body-scrollable-target", gs = (s, l) => s.projectName.toLowerCase().includes(l.toLowerCase()) || s.shortCode.toLowerCase().includes(l.toLowerCase()) || s.loanStatus.label.toLowerCase().includes(l.toLowerCase()) || s.loanType.toLowerCase().includes(l.toLowerCase()), fs = () => {
  const s = E(), l = s.displayView === "GRID" ? ms : xs, a = s.loans.filter((i) => s.selectedLoansFilter === "MY_LOANS" ? i.iAmAdded : s.selectedLoansFilter === "ALL_LOANS").filter((i) => gs(i, s.loansFilterQuery));
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      "data-ui": s.activeMobileTab === "LOANS" ? "active" : "",
      id: us,
      className: p(
        "hidden data-active:flex sm:flex flex-1 flex-col py-3 lg:py-10 px-4 lg:px-6 overflow-y-auto gap-6 scrollbar-stable",
        {
          "lg:grid-cols-2 xl:grid-cols-3": s.displayView === "GRID"
        }
      ),
      children: [
        /* @__PURE__ */ e.jsx("div", { className: "lg:hidden", children: /* @__PURE__ */ e.jsx(je, {}) }),
        !s.loadingLoans && a.length === 0 && /* @__PURE__ */ e.jsxs(
          T,
          {
            className: "flex-1 items-center justify-center",
            space: "md",
            children: [
              /* @__PURE__ */ e.jsx(h, { name: "BigExclamation", className: "text-black-20", width: 64, height: 64 }),
              /* @__PURE__ */ e.jsx(n, { size: "sm", children: "No results match your search" })
            ]
          }
        ),
        s.loadingLoans || a.length > 0 && /* @__PURE__ */ e.jsxs("div", { className: p("grid grid-cols-1 gap-5 content-start flex-1", {
          "lg:grid-cols-2 xl:grid-cols-3": s.displayView === "GRID"
        }), children: [
          s.loadingLoans && /* @__PURE__ */ e.jsx(hs, {}),
          a.map((i) => /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(
            l,
            {
              highlightText: s.loansFilterQuery,
              loan: i
            },
            i.id
          ) }, i.id))
        ] })
      ]
    }
  );
}, Le = () => {
  const s = E();
  return /* @__PURE__ */ e.jsxs(
    Xe,
    {
      onFilesDrop: (l) => s.uploadFilesToShoeBox(l, []),
      onClick: (l) => l.preventDefault(),
      onKeyUp: (l) => l.preventDefault(),
      className: "bg-blue-10 relative px-4 py-10 flex-col gap-3 w-full border-t border-gray-neutral-80 flex items-center justify-center",
      children: [
        /* @__PURE__ */ e.jsx("div", { className: "flex-1", children: /* @__PURE__ */ e.jsx(
          h,
          {
            name: "CloudUpload",
            strokeWidth: 1.5,
            width: 41,
            height: 41,
            className: "text-blue-100"
          }
        ) }),
        /* @__PURE__ */ e.jsx("div", { className: "text-center flex flex-col gap-3", children: /* @__PURE__ */ e.jsxs(n, { size: "sm", as: "div", children: [
          "Drag and Drop or",
          " ",
          /* @__PURE__ */ e.jsxs(
            n,
            {
              "data-upload-trigger": "true",
              size: "sm",
              as: "span",
              variant: "blue",
              className: "underline cursor-pointer",
              children: [
                "Browse my Device",
                " "
              ]
            }
          ),
          "to upload"
        ] }) })
      ]
    }
  );
};
Le.displayName = "GlobalShoeFooter";
const Fs = (s) => {
  const l = s.displayView === "GRID", a = s.displayView === "LIST";
  return /* @__PURE__ */ e.jsxs("div", { className: "rounded-md border-gray-neutral-80 border flex", children: [
    /* @__PURE__ */ e.jsxs(
      F,
      {
        onClick: () => s.onChange("GRID"),
        name: "intent",
        variant: "ghost",
        type: "submit",
        size: "sm",
        value: "GRID",
        className: p(
          "rounded-none flex gap-1 font-normal",
          {
            "text-gray-neutral-70": a,
            "bg-gray-neutral-32": l
          }
        ),
        children: [
          /* @__PURE__ */ e.jsx(h, { strokeWidth: 2, name: "ViewGrid", width: 16, height: 16 }),
          /* @__PURE__ */ e.jsx(n, { className: "hidden lg:inline-flex", size: "sm", children: "Card View" })
        ]
      }
    ),
    /* @__PURE__ */ e.jsx(K, { orientation: "vertical", className: "inline-flex flex-1 bg-gray-neutral-80" }),
    /* @__PURE__ */ e.jsxs(
      F,
      {
        onClick: () => s.onChange("LIST"),
        name: "intent",
        variant: "ghost",
        type: "submit",
        size: "sm",
        value: "LIST",
        className: p(
          "rounded-none flex gap-1 font-normal",
          {
            "text-gray-neutral-70": l,
            "bg-gray-neutral-32": a
          }
        ),
        children: [
          /* @__PURE__ */ e.jsx(h, { strokeWidth: 2, name: "Table2Columns", width: 16, height: 16 }),
          /* @__PURE__ */ e.jsx(n, { className: "hidden lg:inline-flex", size: "sm", children: "Table View" })
        ]
      }
    )
  ] });
}, J = () => {
  const s = E();
  return /* @__PURE__ */ e.jsxs("div", { className: "flex flex-row bg-white px-4 py-2 lg:px-4 lg:py-4 w-full border-b border-gray-neutral-80 items-center", children: [
    /* @__PURE__ */ e.jsxs("div", { className: "flex justify-between w-full", children: [
      /* @__PURE__ */ e.jsxs("div", { className: "shrink-0", children: [
        /* @__PURE__ */ e.jsx(
          F,
          {
            className: "sm:hidden",
            onClick: s.onClose,
            size: "sm",
            variant: "secondary",
            children: /* @__PURE__ */ e.jsx(h, { name: "NavArrowLeft", width: 18, height: 18, strokeWidth: 1.5 })
          }
        ),
        s.hideLoanFilter ? /* @__PURE__ */ e.jsx(n, { size: "sm", children: "My Loans" }) : /* @__PURE__ */ e.jsx(
          Ye,
          {
            active: s.selectedLoansFilter,
            onFilterChange: s.setLoansFilter
          }
        )
      ] }),
      /* @__PURE__ */ e.jsx("div", { className: "pr-2 max-w-md w-full lg:flex justify-end hidden", children: /* @__PURE__ */ e.jsx(je, {}) })
    ] }),
    /* @__PURE__ */ e.jsxs("div", { className: "flex gap-4 justify-end items-center shrink-0", children: [
      /* @__PURE__ */ e.jsx("div", { className: "hidden sm:block", children: /* @__PURE__ */ e.jsx(
        Fs,
        {
          displayView: s.displayView,
          onChange: s.setDisplayView
        }
      ) }),
      !s.hideLoanFilter && /* @__PURE__ */ e.jsxs(
        F,
        {
          onClick: () => s.goToLoanCreatePage(),
          size: "sm",
          className: "gap gap-1 rounded-md px-2 lg:px-3 truncate ",
          children: [
            /* @__PURE__ */ e.jsx(h, { name: "Plus", strokeWidth: 1.5, width: 16, height: 16 }),
            /* @__PURE__ */ e.jsx("span", { className: "hidden lg:flex", children: "Start loan" })
          ]
        }
      )
    ] })
  ] });
};
J.displayName = "GlobalShoeHeader";
const pe = () => {
  const s = Ae(null), [l, a] = X(""), i = E(), r = i.team.filter((d) => d.name.toLowerCase().includes(l.toLowerCase())).sort((d, L) => {
    if (i.teamIdsSorted.length > 0) {
      const w = i.teamIdsSorted.indexOf(d.id), S = i.teamIdsSorted.indexOf(L.id);
      if (w !== -1 && S !== -1)
        return w - S;
      if (w !== -1)
        return -1;
      if (S !== -1)
        return 1;
    }
    return d.name.localeCompare(L.name);
  }), x = i.me.name.toLowerCase().includes(l.toLowerCase()), u = me("(min-width: 768px)") ? Je : he;
  return /* @__PURE__ */ e.jsxs(
    u,
    {
      onOpenChange: () => i.onUnmount(),
      children: [
        /* @__PURE__ */ e.jsx(u.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
          F,
          {
            className: "px-1",
            type: "button",
            variant: "ghost",
            size: "sm",
            children: /* @__PURE__ */ e.jsx(h, { name: "NavArrowDown", width: 18, height: 18, strokeWidth: 1.5 })
          }
        ) }),
        /* @__PURE__ */ e.jsxs(
          u.Content,
          {
            align: "start",
            side: "bottom",
            alignOffset: 0,
            className: "md:w-60 h-96 md:h-80 px-0 border-gray-neutral-80 flex flex-col",
            children: [
              /* @__PURE__ */ e.jsxs(
                M,
                {
                  wrapClassName: "mx-2",
                  variant: "sm",
                  value: l,
                  onChange: (d) => a(d.target.value),
                  ref: s,
                  placeholder: "Search",
                  children: [
                    /* @__PURE__ */ e.jsx(M.Prefix, { children: /* @__PURE__ */ e.jsx(h, { name: "Search", className: "mr-2 shrink-0 text-gray-neutral-100", width: 18, height: 18 }) }),
                    l && /* @__PURE__ */ e.jsx(M.Suffix, { children: /* @__PURE__ */ e.jsx(
                      F,
                      {
                        onClick: () => {
                          a(""), s != null && s.current && s.current.focus();
                        },
                        size: "sm",
                        className: "px-1",
                        variant: "ghost",
                        children: /* @__PURE__ */ e.jsx(h, { name: "Cancel", width: 18, height: 18, strokeWidth: 1.5 })
                      }
                    ) })
                  ]
                }
              ),
              /* @__PURE__ */ e.jsxs(
                T,
                {
                  space: "sm",
                  className: "flex-col px-2 mt-2 scrollbar-stable py-3 flex-1 overflow-y-scroll",
                  children: [
                    !x && !r.length && /* @__PURE__ */ e.jsxs("div", { className: "flex items-center justify-center flex-col h-64 gap-4 ", children: [
                      /* @__PURE__ */ e.jsx(h, { name: "BigExclamation", width: 64, height: 64, className: "text-black-10" }),
                      /* @__PURE__ */ e.jsx(n, { size: "sm", children: "User not found" })
                    ] }),
                    x && /* @__PURE__ */ e.jsxs(
                      T,
                      {
                        row: !0,
                        className: "gap-2",
                        children: [
                          /* @__PURE__ */ e.jsx(
                            P,
                            {
                              onCheckedChange: () => i.onCheckTeamUser(i.me.id),
                              checked: i.teamUserIds.includes(i.me.id),
                              size: "sm",
                              className: "bg-white"
                            }
                          ),
                          /* @__PURE__ */ e.jsxs(y, { size: "xsm", children: [
                            /* @__PURE__ */ e.jsx(y.Fallback, { className: "bg-blue-100 text-white", children: i.me.initials }),
                            /* @__PURE__ */ e.jsx(y.Image, { src: i.me.avatarUrl })
                          ] }),
                          /* @__PURE__ */ e.jsxs(
                            n,
                            {
                              as: "div",
                              size: "sm",
                              truncate: !0,
                              children: [
                                i.me.givenName,
                                " ",
                                /* @__PURE__ */ e.jsx(n, { size: "sm", as: "span", variant: "secondary", children: "(me)" })
                              ]
                            }
                          ),
                          i.me.filesCount > 0 && /* @__PURE__ */ e.jsx(O, { variant: "warning", circle: !0, className: "w-5 text-xxs aspect-square h-5 flex items-center justify-center", children: i.me.filesCount })
                        ]
                      }
                    ),
                    r.length > 0 && /* @__PURE__ */ e.jsxs(T, { space: "sm", children: [
                      /* @__PURE__ */ e.jsx(n, { size: "sm", children: "Team Shoebox" }),
                      r.map((d) => /* @__PURE__ */ e.jsxs(
                        T,
                        {
                          row: !0,
                          space: "sm",
                          className: "items-center hover:bg-gray-neutral-30 rounded-md min-h-9 px-2 py-2",
                          children: [
                            /* @__PURE__ */ e.jsx(
                              P,
                              {
                                onCheckedChange: () => i.onCheckTeamUser(d.id),
                                checked: i.teamUserIds.includes(d.id),
                                size: "sm",
                                className: "bg-white"
                              }
                            ),
                            /* @__PURE__ */ e.jsxs(y, { size: "xsm", children: [
                              /* @__PURE__ */ e.jsx(y.Fallback, { className: "bg-blue-100 text-white", children: d.initials }),
                              /* @__PURE__ */ e.jsx(y.Image, { src: d.avatarUrl })
                            ] }),
                            /* @__PURE__ */ e.jsx(n, { size: "sm", truncate: !0, children: d.name }),
                            d.filesCount > 0 && /* @__PURE__ */ e.jsx(O, { variant: "warning", circle: !0, className: "w-5 text-xxs aspect-square h-5 flex items-center justify-center", children: d.filesCount })
                          ]
                        },
                        d.id
                      ))
                    ] })
                  ]
                }
              )
            ]
          }
        )
      ]
    }
  );
}, Ss = () => {
  const s = E();
  return /* @__PURE__ */ e.jsxs("div", { className: "sm:hidden", children: [
    /* @__PURE__ */ e.jsx(J, {}),
    /* @__PURE__ */ e.jsxs("div", { className: "flex flex-1 text-center border-b border-gray-neutral-80", children: [
      /* @__PURE__ */ e.jsxs(
        n,
        {
          onClick: () => s.setActiveMobileTab("SHOEBOX"),
          as: "div",
          weight: "medium",
          variant: s.activeMobileTab === "SHOEBOX" ? "blue" : "primary",
          className: p("flex-1 flex items-center justify-center py-2 border-b-2", {
            "border-blue-100": s.activeMobileTab === "SHOEBOX",
            "border-transparent": s.activeMobileTab !== "SHOEBOX"
          }),
          children: [
            "My Shoebox ",
            /* @__PURE__ */ e.jsxs(C, { children: [
              /* @__PURE__ */ e.jsx(C.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(F, { size: "sm", variant: "ghost", children: /* @__PURE__ */ e.jsx(h, { name: "InfoEmpty", width: 16, height: 16, strokeWidth: 1.5 }) }) }),
              /* @__PURE__ */ e.jsxs(C.Content, { className: "max-w-xs p-4 rounded-md", children: [
                /* @__PURE__ */ e.jsxs(T, { row: !0, space: "sm", className: "items-center", children: [
                  /* @__PURE__ */ e.jsx(y, { size: "xs", className: "rounded-md", children: /* @__PURE__ */ e.jsx(y.Fallback, { className: "p-0 bg-blue-100 text-white ", children: /* @__PURE__ */ e.jsx(h, { strokeWidth: 1.5, name: "Box", width: 16, height: 16 }) }) }),
                  /* @__PURE__ */ e.jsx(n, { size: "sm", weight: "medium", children: "Global Shoebox" })
                ] }),
                /* @__PURE__ */ e.jsx("div", { className: "flex flex-col gap-2", children: /* @__PURE__ */ e.jsx(n, { variant: "inherit", size: "sm", children: "Upload and Select to Move or Add items to your existing loans" }) })
              ] })
            ] }),
            !s.hideLoanFilter && /* @__PURE__ */ e.jsx(pe, {})
          ]
        }
      ),
      /* @__PURE__ */ e.jsx(
        n,
        {
          onClick: () => s.setActiveMobileTab("LOANS"),
          as: "div",
          weight: "medium",
          variant: s.activeMobileTab === "LOANS" ? "blue" : "primary",
          className: p("flex-1 py-2 border-b-2", {
            "border-blue-100": s.activeMobileTab === "LOANS",
            "border-transparent": s.activeMobileTab !== "LOANS"
          }),
          children: "Loan"
        }
      )
    ] })
  ] });
}, Ee = {
  "image/*": [],
  "video/*": [],
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/zip": [".zip"],
  "application/x-rar-compressed": [".rar"],
  "text/plain": [".txt"],
  "text/csv": [".csv"],
  "application/json": [".json"],
  "application/xml": [".xml"]
}, be = {
  Zip: {
    icon: "Zip",
    label: "Compressed (zip)"
  },
  Audio: {
    icon: "Audio",
    label: "Audios"
  },
  Docusign: {
    icon: "Docusign",
    label: "Docusign"
  },
  Folder: {
    icon: "ClientFolder",
    label: "Folders"
  },
  Form: {
    icon: "Forms",
    label: "Forms"
  },
  Pdf: {
    icon: "Pdf",
    label: "PDFs"
  },
  Photo: {
    icon: "PhotosAndImages",
    label: "Photos & Images"
  },
  PowerPoint: {
    icon: "MsPowerpoint",
    label: "Presentations"
  },
  Excel: {
    icon: "MsExcel",
    label: "Spreadsheets"
  },
  Video: {
    icon: "Video",
    label: "Videos"
  },
  Document: {
    icon: "MsWord",
    label: "Word"
  }
}, js = Object.entries(be).filter(([s]) => !["Docusign", "Folder"].includes(s)).map(([s, l]) => ({ ...l, type: s })), re = (s) => /* @__PURE__ */ e.jsxs(H, { modal: !1, children: [
  /* @__PURE__ */ e.jsxs(
    T,
    {
      row: !0,
      space: "md",
      className: p("hover:bg-blue-20 max-w-36 shrink-0 overflow-hidden rounded-md px-1 xl:px-2 py-1 border border-gray-neutral-80 items-center justify-center cursor-pointer", {
        "bg-gray-neutral-30 hover:bg-gray-neutral-30 rounded-md": s.selectedFilters.length > 0
      }),
      children: [
        /* @__PURE__ */ e.jsx(H.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsxs(
          T,
          {
            row: !0,
            className: "overflow-hidden gap-1 sm:gap-0 xl:gap-2",
            children: [
              s.selectedFilters.length > 0 && /* @__PURE__ */ e.jsx(
                h,
                {
                  name: "Check",
                  width: 20,
                  strokeWidth: 1.5
                }
              ),
              /* @__PURE__ */ e.jsx(
                n,
                {
                  size: "sm",
                  truncate: !0,
                  weight: "medium",
                  children: s.selectedFilters.length > 0 ? be[s.selectedFilters[0]].label : "Type"
                }
              ),
              s.selectedFilters.length === 0 && /* @__PURE__ */ e.jsx(h, { name: "NavArrowDown", width: 20 })
            ]
          }
        ) }),
        s.selectedFilters.length > 0 && /* @__PURE__ */ e.jsx(
          "button",
          {
            onClick: (l) => {
              l.preventDefault(), s.onFilterSelectedChange([]);
            },
            children: /* @__PURE__ */ e.jsx(h, { name: "Cancel", width: 20 })
          }
        )
      ]
    }
  ),
  /* @__PURE__ */ e.jsx(
    H.Content,
    {
      withPortal: !0,
      align: "start",
      side: "bottom",
      sideOffset: 10,
      alignOffset: -8,
      className: "z-tooltip",
      children: js.map((l) => /* @__PURE__ */ e.jsxs(
        H.Item,
        {
          onSelect: (a) => {
            s.onFilterSelectedChange(
              s.selectedFilters.includes(l.type) ? s.selectedFilters.filter((i) => i !== l.type) : [...s.selectedFilters, l.type]
            ), a.preventDefault();
          },
          className: "w-full flex items-center first:mt-0 p-2 gap-2",
          children: [
            /* @__PURE__ */ e.jsx(
              P,
              {
                className: "bg-white",
                checked: s.selectedFilters.includes(l.type),
                size: "sm"
              }
            ),
            /* @__PURE__ */ e.jsx(
              h,
              {
                name: l.icon,
                width: 20,
                height: 20
              }
            ),
            /* @__PURE__ */ e.jsx(n, { className: "ml-3", size: "sm", children: l.label }),
            s.selectedFilters.includes(l.type) && /* @__PURE__ */ e.jsx(
              h,
              {
                name: "Check",
                width: 20,
                strokeWidth: 1.5,
                className: "ml-auto mr-0 text-blue-100"
              }
            )
          ]
        },
        l.type
      ))
    }
  )
] }), Ls = () => /* @__PURE__ */ e.jsxs("div", { className: "relative flex-1 gap-3 flex items-center justify-center flex-col", children: [
  /* @__PURE__ */ e.jsx(
    y,
    {
      size: "lg",
      rounded: "md",
      children: /* @__PURE__ */ e.jsx(y.Fallback, { className: "p-1", children: /* @__PURE__ */ e.jsx(h, { strokeWidth: 2, name: "Box", width: 16, height: 16 }) })
    }
  ),
  /* @__PURE__ */ e.jsx(n, { variant: "inherit", size: "sm", children: "No shoebox item" }),
  /* @__PURE__ */ e.jsx(n, { size: "sm", variant: "secondary", children: "Nothing to move or add." })
] }), ps = (s) => {
  const l = E(), { attributes: a, listeners: i, isDragging: r, setNodeRef: x } = ze({
    id: s.file.id,
    data: s.file
  }), m = l.isFileChecked(s.file), u = [s.file, ...l.checkedFiles.filter((d) => d.id !== s.file.id)];
  return /* @__PURE__ */ e.jsx(
    $e,
    {
      file: s.file,
      checked: m,
      onCheckFilesFromOrToFile: (d) => l.checkFilesFromOrToFile(d),
      isDragging: r,
      onMove: () => l.setMoveToLoanShoeBoxFiles(u),
      onDelete: () => l.setStagedFilesForDeletion(u),
      onRename: (d) => l.setRenameShoeBoxFile(d),
      onDownload: (d) => {
        var L;
        return (L = l.onDownloadShoeBoxFiles) == null ? void 0 : L.call(l, [d]);
      },
      onOpenInNewTab: (d) => l.openFileInNewTab(d),
      onClick: (d) => l.openFileDoubleClick(d),
      onCheckChange: (d, L) => d ? l.checkFile(L) : l.unCheckFile(L),
      onCancelUpload: (d) => l.cancelUploadingFile(d),
      ...a,
      ...i,
      ref: x
    }
  );
}, Es = (s) => {
  const l = s.isInsideDialog ? C.Close : "div";
  return /* @__PURE__ */ e.jsxs(g, { children: [
    /* @__PURE__ */ e.jsx(g.Trigger, { children: /* @__PURE__ */ e.jsx(
      h,
      {
        name: "InfoEmpty",
        className: p({
          "text-gray-neutral-70": s.loans.length === 0,
          "text-primary": s.loans.length > 0
        }),
        width: 14,
        height: 14,
        strokeWidth: 1.5
      }
    ) }),
    /* @__PURE__ */ e.jsx(
      g.Content,
      {
        align: "start",
        side: "bottom",
        alignOffset: 0,
        className: "p-2 max-w-52",
        children: /* @__PURE__ */ e.jsxs(T, { row: !0, space: "xs", children: [
          /* @__PURE__ */ e.jsx(
            h,
            {
              className: p({
                "text-gray-neutral-70": s.loans.length === 0,
                "text-primary": s.loans.length > 0
              }),
              name: "InfoEmpty",
              width: 14,
              height: 14,
              strokeWidth: 1.5
            }
          ),
          /* @__PURE__ */ e.jsxs(
            T,
            {
              space: "xs",
              className: "overflow-hidden",
              children: [
                /* @__PURE__ */ e.jsxs(n, { size: "xs", children: [
                  s.loans.length > 0 ? `User is on ${s.loans.length} active loans` : "User external to mysherpas",
                  "."
                ] }),
                /* @__PURE__ */ e.jsx(
                  n,
                  {
                    italic: !0,
                    variant: "secondary",
                    size: "xs",
                    children: "Sent via Secure Upload"
                  }
                ),
                s.loans.map((a) => /* @__PURE__ */ e.jsx(
                  l,
                  {
                    asChild: !0,
                    children: /* @__PURE__ */ e.jsxs(
                      n,
                      {
                        onClick: () => s.onGoToLoan(a.id),
                        truncate: !0,
                        size: "xs",
                        className: "cursor-pointer hover:underline text-primary-500",
                        children: [
                          a.shortCode,
                          " : ",
                          a.projectName
                        ]
                      }
                    )
                  },
                  a.shortCode
                ))
              ]
            }
          )
        ] })
      }
    )
  ] });
}, Te = (s) => {
  var a, i;
  const l = qe(s.folder.files).map((r) => {
    var x, m, u, d;
    return {
      ...r,
      uploadedByDisplayName: ((m = (x = r.items) == null ? void 0 : x[0]) == null ? void 0 : m.uploadedByDisplayName) || "Unknown",
      uploadedByEmailAddress: ((d = (u = r.items) == null ? void 0 : u[0]) == null ? void 0 : d.uploadedByEmailAddress) || "Unknown",
      messages: es(r.items)
    };
  });
  return /* @__PURE__ */ e.jsxs(
    I.Item,
    {
      className: "mb-2 last:mb-0",
      value: s.folder.key,
      children: [
        /* @__PURE__ */ e.jsx(
          I.Trigger,
          {
            asChild: !0,
            className: "flex items-start gap-2 overflow-hidden cursor-pointer border-b border-gray-neutral-80",
            children: /* @__PURE__ */ e.jsx(T, { space: "xs", className: "overflow-hidden flex-1", children: /* @__PURE__ */ e.jsxs("div", { className: "flex items-center overflow-hidden gap-2 flex-1", children: [
              /* @__PURE__ */ e.jsx(
                O,
                {
                  "aria-label": "Files Count",
                  variant: "secondary",
                  className: "w-5 text-xxs aspect-square h-5 flex items-center justify-center",
                  children: s.folder.filesCount
                }
              ),
              /* @__PURE__ */ e.jsx(
                n,
                {
                  size: "sm",
                  weight: "medium",
                  as: "div",
                  truncate: !0,
                  className: "overflow-hidden",
                  children: s.folder.owner === "ME" ? /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                    s.folder.senderGivenName,
                    /* @__PURE__ */ e.jsx(n, { as: "span", variant: "secondary", size: "sm", children: " (Me)" })
                  ] }) : s.folder.senderName
                }
              ),
              s.folder.senderName && s.folder.owner !== "ME" && /* @__PURE__ */ e.jsx(n, { truncate: !0, size: "xs", as: "span", className: "text-gray-neutral-70 pl-2 flex-1", children: s.folder.senderEmailAddress }),
              s.folder.subFolders.length === 0 && /* @__PURE__ */ e.jsx(
                Es,
                {
                  loans: s.folder.loans,
                  isInsideDialog: !0,
                  onGoToLoan: s.onGoToLoan
                }
              )
            ] }) })
          }
        ),
        /* @__PURE__ */ e.jsxs(I.Content, { className: "py-0 pr-0 pl-2", children: [
          /* @__PURE__ */ e.jsx("div", { className: "flex flex-col gap-4 pl-2.5 border-l border-gray-neutral-80 mt-2 mb-2 last:mb-0", children: l.map((r) => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
            /* @__PURE__ */ e.jsxs(n, { as: "div", size: "xs", weight: "medium", className: "text-gray-neutral-70 flex flex-row gap-2", children: [
              r.title,
              r.messages.length > 0 && /* @__PURE__ */ e.jsx(
                "div",
                {
                  className: "flex items-center",
                  role: "presentation",
                  tabIndex: 0,
                  onKeyUp: (x) => x.stopPropagation(),
                  onClick: (x) => x.stopPropagation(),
                  children: /* @__PURE__ */ e.jsxs(C, { children: [
                    /* @__PURE__ */ e.jsxs(g, { children: [
                      /* @__PURE__ */ e.jsx(
                        g.Trigger,
                        {
                          asChild: !0,
                          children: /* @__PURE__ */ e.jsxs(
                            T,
                            {
                              as: "button",
                              "aria-label": "Messages Count",
                              row: !0,
                              space: "xs",
                              children: [
                                /* @__PURE__ */ e.jsx(n, { size: "xs", children: r.messages.length }),
                                /* @__PURE__ */ e.jsx(h, { name: "MessageText", width: 18, height: 18 })
                              ]
                            }
                          )
                        }
                      ),
                      /* @__PURE__ */ e.jsxs(
                        g.Content,
                        {
                          align: "start",
                          side: "bottom",
                          alignOffset: 0,
                          className: "flex flex-row gap-2 p-1 px-2 items-center",
                          children: [
                            /* @__PURE__ */ e.jsxs(n, { size: "sm", as: "span", children: [
                              r.messages.length,
                              " ",
                              r.messages.length > 1 ? "messages" : "message",
                              "."
                            ] }),
                            " ",
                            /* @__PURE__ */ e.jsx(
                              C.Trigger,
                              {
                                asChild: !0,
                                children: /* @__PURE__ */ e.jsx(n, { size: "sm", variant: "blue", underline: !0, children: "Click to View" })
                              }
                            )
                          ]
                        }
                      )
                    ] }),
                    /* @__PURE__ */ e.jsxs(C.Content, { className: "sm:max-w-2xl", children: [
                      /* @__PURE__ */ e.jsxs(T, { row: !0, space: "sm", className: "items-center px-4 pt-3", children: [
                        /* @__PURE__ */ e.jsx(h, { name: "MessageText", width: 18, height: 18 }),
                        /* @__PURE__ */ e.jsx(n, { size: "sm", weight: "medium", as: "div", truncate: !0, className: "overflow-hidden flex-1", children: s.folder.owner === "ME" ? "me" : /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                          r.uploadedByDisplayName,
                          /* @__PURE__ */ e.jsx(n, { size: "xs", as: "span", className: "text-gray-neutral-70 pl-2 flex-1", children: r.uploadedByEmailAddress })
                        ] }) }),
                        /* @__PURE__ */ e.jsx(
                          C.Close,
                          {
                            asChild: !0,
                            children: /* @__PURE__ */ e.jsx(
                              F,
                              {
                                "aria-label": "Close Messages",
                                size: "sm",
                                variant: "ghost",
                                children: /* @__PURE__ */ e.jsx(h, { name: "Cancel", width: 20, height: 20, strokeWidth: 1.5 })
                              }
                            )
                          }
                        )
                      ] }),
                      /* @__PURE__ */ e.jsx(K, {}),
                      /* @__PURE__ */ e.jsx(
                        "section",
                        {
                          "aria-label": "Messages List",
                          className: "flex flex-col gap-4 py-4 px-8 scrollbar-stable overflow-y-auto max-h-96",
                          children: r.messages.map((x, m) => /* @__PURE__ */ e.jsx(n, { size: "sm", className: "p-4 rounded-md border leading-6", as: "div", children: x }, m))
                        }
                      )
                    ] })
                  ] })
                }
              )
            ] }),
            r.items.map((x) => /* @__PURE__ */ e.jsx(ps, { file: x }, x.id))
          ] }, r.title)) }),
          /* @__PURE__ */ e.jsx(
            I,
            {
              className: "divide-y",
              collapsible: !0,
              type: "single",
              defaultValue: ((i = (a = s.folder.subFolders) == null ? void 0 : a[0]) == null ? void 0 : i.key) ?? "",
              children: s.folder.subFolders.map((r) => /* @__PURE__ */ e.jsx(
                Te,
                {
                  folder: r,
                  onGoToLoan: s.onGoToLoan
                },
                r.key
              ))
            }
          )
        ] })
      ]
    },
    s.folder.key
  );
}, bs = () => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
  /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-10" }),
  /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-10" }),
  /* @__PURE__ */ e.jsx(R, { className: "bg-gray-neutral-35 w-full h-10" })
] }), ye = () => {
  var r, x;
  const s = E(), l = j(
    (m, u) => {
      s.uploadFilesToShoeBox(m, u.map((d) => d.file));
    },
    [s]
  ), a = ge({
    accept: Ee,
    onDrop: l,
    multiple: !0
  }), i = s.folders.filter((m) => m.filesCount > 0).filter((m) => fe(m, s.teamUserIds));
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      role: "button",
      tabIndex: 0,
      ...a.getRootProps(),
      onClick: (m) => m.preventDefault(),
      onKeyUp: (m) => m.preventDefault(),
      "data-ui": s.activeMobileTab === "SHOEBOX" ? "active" : "",
      className: `flex-1 relative sm:col-span-5 md:col-span-4 xl:col-span-3 
      hidden data-active:flex
      border-r border-gray-neutral-80 sm:flex flex-col overflow-hidden`,
      children: [
        /* @__PURE__ */ e.jsx(
          "input",
          {
            ...a.getInputProps(),
            "data-testid": "lender-shoebox-sidebar-drop-target-input"
          }
        ),
        /* @__PURE__ */ e.jsx(
          n,
          {
            "data-ui": a.isDragActive ? "active" : "",
            size: "sm",
            as: "div",
            weight: "medium",
            className: "absolute overflow-hidden px-4 items-center justify-center flex text-blue-100 z-10 text-right opacity-0 w-0 h-0 data-active:w-full data-active:h-full data-active:opacity-100 inset-0 bg-blue-50 bg-opacity-50",
            children: /* @__PURE__ */ e.jsx("div", { className: "p-2 rounded-md bg-blue-50", children: "DROP FILES HERE" })
          }
        ),
        /* @__PURE__ */ e.jsxs("div", { className: "hidden sm:flex bg-white p-3  flex-col gap-8 border-b border-gray-neutral-80", children: [
          /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsxs(
            F,
            {
              onClick: s.onClose,
              size: "sm",
              variant: "secondary",
              children: [
                /* @__PURE__ */ e.jsx(h, { name: "NavArrowLeft", width: 18, height: 18, strokeWidth: 1.5 }),
                /* @__PURE__ */ e.jsx(n, { size: "sm", className: "hidden sm:inline-flex", children: "Back to Dashboard" })
              ]
            }
          ) }),
          /* @__PURE__ */ e.jsxs(T, { space: "sm", row: !0, justify: "between", children: [
            /* @__PURE__ */ e.jsxs(n, { weight: "medium", as: "div", className: "gap-2 items-center hidden sm:flex", children: [
              "My ShoeBox",
              /* @__PURE__ */ e.jsxs(g, { children: [
                /* @__PURE__ */ e.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx("button", { children: /* @__PURE__ */ e.jsx(
                  h,
                  {
                    strokeWidth: 1.5,
                    name: "InfoEmpty",
                    width: 16,
                    height: 16
                  }
                ) }) }),
                /* @__PURE__ */ e.jsxs(
                  g.Content,
                  {
                    align: "start",
                    side: "bottom",
                    alignOffset: 0,
                    className: "p-4 flex gap-3 z-dialog w-80",
                    children: [
                      /* @__PURE__ */ e.jsx(y, { size: "xs", className: "rounded-md", children: /* @__PURE__ */ e.jsx(y.Fallback, { className: "p-0 bg-blue-100 text-white ", children: /* @__PURE__ */ e.jsx(h, { strokeWidth: 1.5, name: "Box", width: 16, height: 16 }) }) }),
                      /* @__PURE__ */ e.jsxs("div", { className: "flex flex-col gap-2", children: [
                        /* @__PURE__ */ e.jsx(n, { size: "sm", weight: "medium", children: "My Shoebox" }),
                        /* @__PURE__ */ e.jsx(n, { variant: "inherit", size: "sm", children: "Upload to your shoebox and select/drag to add items to your existing loan" })
                      ] })
                    ]
                  }
                )
              ] }),
              !s.hideLoanFilter && /* @__PURE__ */ e.jsx(pe, {})
            ] }),
            /* @__PURE__ */ e.jsx(
              re,
              {
                onFilterSelectedChange: s.setFilterTypes,
                selectedFilters: s.filterTypes
              }
            )
          ] })
        ] }),
        /* @__PURE__ */ e.jsxs("div", { className: "mt-5 px-4 min-h-10", children: [
          s.totalCheckedFiles === 0 && /* @__PURE__ */ e.jsx("div", { className: "flex flex-row justify-end sm:hidden", children: /* @__PURE__ */ e.jsx(
            re,
            {
              onFilterSelectedChange: s.setFilterTypes,
              selectedFilters: s.filterTypes
            }
          ) }),
          s.totalCheckedFiles > 0 && /* @__PURE__ */ e.jsxs("div", { className: "bg-gray-neutral-30 px-3 rounded-full flex items-center gap-2", children: [
            /* @__PURE__ */ e.jsx(
              F,
              {
                onClick: s.unCheckAllFiles,
                className: "p-0",
                variant: "ghost",
                children: /* @__PURE__ */ e.jsx(h, { name: "Cancel", width: 18, height: 18 })
              }
            ),
            /* @__PURE__ */ e.jsxs(n, { size: "xs", className: "flex-1", children: [
              s.totalCheckedFiles,
              " Selected"
            ] }),
            /* @__PURE__ */ e.jsxs(g, { children: [
              /* @__PURE__ */ e.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                F,
                {
                  onClick: () => {
                    var m;
                    return (m = s.onDownloadShoeBoxFiles) == null ? void 0 : m.call(s, s.checkedFiles);
                  },
                  variant: "ghost",
                  className: "p-1",
                  children: /* @__PURE__ */ e.jsx(
                    h,
                    {
                      name: "DownloadMenu",
                      strokeWidth: 2,
                      width: 20,
                      height: 20
                    }
                  )
                }
              ) }),
              /* @__PURE__ */ e.jsx(g.Content, { align: "start", side: "bottom", alignOffset: 0, children: "Download" })
            ] }),
            /* @__PURE__ */ e.jsxs(g, { children: [
              /* @__PURE__ */ e.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                F,
                {
                  onClick: () => s.setMoveToLoanShoeBoxFiles(s.checkedFiles),
                  variant: "ghost",
                  className: "p-1",
                  children: /* @__PURE__ */ e.jsx(
                    h,
                    {
                      name: "FolderMoveUp",
                      strokeWidth: 2,
                      width: 20,
                      height: 20
                    }
                  )
                }
              ) }),
              /* @__PURE__ */ e.jsx(g.Content, { align: "start", side: "bottom", alignOffset: 0, children: "Move" })
            ] }),
            /* @__PURE__ */ e.jsxs(g, { children: [
              /* @__PURE__ */ e.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                F,
                {
                  onClick: () => s.setStagedFilesForDeletion(s.checkedFiles),
                  variant: "ghost",
                  className: "p-1",
                  children: /* @__PURE__ */ e.jsx(h, { name: "Trash", strokeWidth: 2, width: 20, height: 20 })
                }
              ) }),
              /* @__PURE__ */ e.jsx(g.Content, { align: "start", side: "bottom", alignOffset: 0, children: "Delete" })
            ] })
          ] })
        ] }),
        !s.loadingShoeBoxFiles && i.length === 0 && /* @__PURE__ */ e.jsx(Ls, {}),
        !s.loadingShoeBoxFiles && i.length > 0 && /* @__PURE__ */ e.jsx("div", { className: "flex-1 w-full py-4 sm:py-8 sm:pt-5 sm:pb-8 px-4  overflow-y-scroll scrollbar-stable", children: /* @__PURE__ */ e.jsx(
          I,
          {
            collapsible: !0,
            className: "divide-y",
            type: "single",
            defaultValue: ((x = (r = s.folders) == null ? void 0 : r[0]) == null ? void 0 : x.key) ?? "",
            children: i.map(
              (m) => /* @__PURE__ */ e.jsx(
                Te,
                {
                  folder: m,
                  onGoToLoan: s.goToLoan
                },
                m.key
              )
            )
          }
        ) }),
        s.loadingShoeBoxFiles && /* @__PURE__ */ e.jsx("div", { className: " flex flex-col w-full gap-4 p-4", children: /* @__PURE__ */ e.jsx(bs, {}) }),
        s.secureUploadPageLink && /* @__PURE__ */ e.jsx("div", { className: "shrink-0 p-4", children: /* @__PURE__ */ e.jsx(
          F,
          {
            asChild: !0,
            variant: "outline",
            className: "space-x-2 w-full",
            children: /* @__PURE__ */ e.jsxs("div", { className: "hover:bg-white overflow-hidden", children: [
              /* @__PURE__ */ e.jsx(h, { name: "Lock", width: 15, height: 15 }),
              " ",
              /* @__PURE__ */ e.jsx(n, { truncate: !0, size: "sm", className: "flex-1", children: "My Secure Upload Page Link" }),
              " ",
              /* @__PURE__ */ e.jsx(Ze, { text: s.secureUploadPageLink })
            ] })
          }
        ) })
      ]
    }
  );
};
ye.displayName = "GlobalShoeSidebar";
const Ts = () => {
  const [s, l] = X("GRID"), [a, i] = X(), r = E(), x = (S) => {
    const { active: v } = S, { data: _ } = v, { current: B } = _;
    i(B);
  }, m = (S) => {
    const { over: v, active: _ } = S;
    if (!v || !_) return;
    const { data: B } = _, { data: G } = v, { current: U } = G, { current: D } = B, W = r.checkedFiles.filter(
      (V) => V.id !== (D == null ? void 0 : D.id)
    );
    i(void 0), r.setDroppedShoeBoxFiles(
      U,
      [...W, D]
    );
  }, u = Q(Ge, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10
    }
  }), d = Q(Ue, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10
    }
  }), L = Q(Ve, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  }), w = Pe(u, d, L);
  return {
    dragItem: a,
    sensors: w,
    displayView: s,
    onDisplayViewChange: l,
    onDragStart: x,
    onDragEnd: m,
    totalCheckedFiles: r.totalCheckedFiles,
    loadingShoeBoxFiles: r.loadingShoeBoxFiles
  };
}, ys = () => {
  var a;
  const s = Ts(), l = E();
  return ce(() => () => {
    l.onSetLoansFilterQuery("");
  }, [l.onSetLoansFilterQuery]), /* @__PURE__ */ e.jsxs(
    He,
    {
      modifiers: [cs, rs],
      sensors: s.sensors,
      onDragStart: s.onDragStart,
      onDragEnd: s.onDragEnd,
      children: [
        /* @__PURE__ */ e.jsxs(
          "div",
          {
            "data-testid": "global-shoe-box-content",
            className: "flex flex-col sm:grid sm:grid-cols-12 h-screen w-full bg-white rounded-md overflow-hidden",
            children: [
              /* @__PURE__ */ e.jsx(Ss, {}),
              /* @__PURE__ */ e.jsx(ye, {}),
              /* @__PURE__ */ e.jsxs(
                "div",
                {
                  "data-ui": l.activeMobileTab === "LOANS" ? "active" : "",
                  className: "col-span-1 data-active:flex-1 sm:col-span-7 md:col-span-8 xl:col-span-9 bg-white-neutral sm:bg-black-10 flex flex-col overflow-hidden",
                  children: [
                    /* @__PURE__ */ e.jsx("div", { className: "hidden sm:flex", children: /* @__PURE__ */ e.jsx(J, {}) }),
                    /* @__PURE__ */ e.jsx(fs, {}),
                    /* @__PURE__ */ e.jsx(Le, {})
                  ]
                }
              )
            ]
          }
        ),
        ((a = s.dragItem) == null ? void 0 : a.id) && /* @__PURE__ */ e.jsx(We, { children: /* @__PURE__ */ e.jsx(
          Qe,
          {
            className: "w-40",
            type: "FILE",
            title: s.dragItem.title,
            documentName: s.dragItem.documentName,
            count: s.totalCheckedFiles
          }
        ) })
      ]
    }
  );
}, vs = () => {
  const s = E(), l = s.stagedFilesForDeletion.length > 1, [a] = s.stagedFilesForDeletion;
  return /* @__PURE__ */ e.jsx(
    ss,
    {
      ariaLabel: "Delete ShoeBox File Confirmation",
      onOpenChange: (i) => !i && s.setStagedFilesForDeletion([]),
      open: s.stagedFilesForDeletion.length > 0,
      onConfirm: () => {
        s.deleteShoeBoxItems(s.stagedFilesForDeletion);
      },
      message: `Are you sure you want to delete ${l ? `${s.stagedFilesForDeletion.length} items` : a == null ? void 0 : a.title}`,
      confirmButtonText: "Delete"
    }
  );
}, Ns = () => {
  const s = E();
  return /* @__PURE__ */ e.jsx(
    Ke,
    {
      items: s.moveToLoanShoeBoxFiles,
      open: s.moveToLoanShoeBoxFiles.length > 0,
      onOpenChange: (l) => {
        l || s.setMoveToLoanShoeBoxFiles([]);
      },
      hideLoanFilter: s.hideLoanFilter,
      loans: s.loans,
      onMoveConfirm: (l) => {
        s.setPickLenderLoan(
          l,
          s.moveToLoanShoeBoxFiles
        );
      }
    }
  );
}, ws = () => {
  const s = E(), l = s.rejectedFiles.length > 1, [a] = s.rejectedFiles;
  return /* @__PURE__ */ e.jsx(
    C,
    {
      open: s.rejectedFiles.length > 0,
      onOpenChange: (i) => {
        i || s.setRejectedFiles([]);
      },
      children: /* @__PURE__ */ e.jsxs(C.Content, { className: "pr-6 pt-3 pl-4 pb-4 gap-3 flex-row flex", children: [
        /* @__PURE__ */ e.jsx(h, { name: "InfoCircleSolid", width: 24, height: 24, className: "text-destructive" }),
        /* @__PURE__ */ e.jsxs("div", { className: "flex flex-col flex-1 gap-2", children: [
          /* @__PURE__ */ e.jsxs(n, { size: "sm", weight: "medium", children: [
            "Error Uploading ",
            l ? `${s.rejectedFiles.length} items` : a == null ? void 0 : a.name
          ] }),
          /* @__PURE__ */ e.jsx(n, { size: "sm", children: "Unable to upload file because file type is not supported" }),
          /* @__PURE__ */ e.jsx(n, { size: "xs", variant: "secondary", children: "Only .jpg/jpeg/png, .mp4, .mp3, .pdf, .doc, .zip, .pdf, .txt files supported" }),
          l && /* @__PURE__ */ e.jsx("div", { className: "overflow-y-scroll scrollbar-stable flex-1 max-h-44 flex flex-col gap-2", children: s.rejectedFiles.map((i) => /* @__PURE__ */ e.jsx(
            n,
            {
              size: "xs",
              children: i.name
            },
            i.name
          )) })
        ] }),
        /* @__PURE__ */ e.jsx(C.Close, { asChild: !0, children: /* @__PURE__ */ e.jsx(F, { variant: "ghost", size: "sm", children: /* @__PURE__ */ e.jsx(h, { name: "Cancel", width: 20, height: 20, strokeWidth: 1.5 }) }) })
      ] })
    }
  );
}, _s = () => {
  var l;
  const s = E();
  return /* @__PURE__ */ e.jsx(
    ls,
    {
      open: !!s.shoeBoxFileToRename,
      onOpenChange: (a) => {
        a || s.setRenameShoeBoxFile(null);
      },
      onRename: (a) => {
        s.shoeBoxFileToRename && s.renameShoeBoxFile(s.shoeBoxFileToRename, a);
      },
      name: ((l = s.shoeBoxFileToRename) == null ? void 0 : l.title) ?? ""
    }
  );
}, Ll = () => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
  /* @__PURE__ */ e.jsx(ys, {}),
  /* @__PURE__ */ e.jsx(ns, {}),
  /* @__PURE__ */ e.jsx(os, {}),
  /* @__PURE__ */ e.jsx(Ns, {}),
  /* @__PURE__ */ e.jsx(_s, {}),
  /* @__PURE__ */ e.jsx(vs, {}),
  /* @__PURE__ */ e.jsx(ws, {})
] }), Cs = Oe((s, l) => {
  const { size: a, isOver: i, ...r } = s, x = E(), m = x.folders.filter((S) => S.filesCount > 0).filter((S) => fe(S, x.teamUserIds)).reduce((S, v) => S + v.filesCount, 0), u = j(
    (S, v) => {
      x.uploadFilesToShoeBox(S, v.map((_) => _.file));
    },
    [x]
  ), d = ge({
    accept: Ee,
    onDrop: u,
    multiple: !0
  }), { onClick: L, ...w } = d.getRootProps();
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      ref: l,
      ...r,
      className: p("cursor-pointer gap-2 flex flex-col text-white rounded-md transition-all will-change-auto  w-full max-w-60", {
        "bg-blue-800": a === "default",
        "h-36 p-3": a === "default",
        "h-28": a === "small"
      }),
      children: [
        /* @__PURE__ */ e.jsxs("div", { className: p("flex gap-2 w-full", {
          "justify-center": a === "small"
        }), children: [
          /* @__PURE__ */ e.jsx(y, { size: "xs", className: "overflow-visible", children: /* @__PURE__ */ e.jsxs(
            y.Fallback,
            {
              className: p("p-0 relative rounded-full", {
                "bg-blue-55": a === "default",
                "bg-transparent": a === "small"
              }),
              children: [
                /* @__PURE__ */ e.jsx(h, { strokeWidth: 1.5, name: "Box", width: 16, height: 16 }),
                m > 0 && a === "small" && /* @__PURE__ */ e.jsx("div", { className: " w-2 aspect-square bg-yellow-60 rounded-full absolute top-0 right-0" })
              ]
            }
          ) }),
          /* @__PURE__ */ e.jsx(
            "div",
            {
              className: p("text-left flex flex-col gap-1 flex-1", {
                hidden: a === "small"
              }),
              children: /* @__PURE__ */ e.jsxs(n, { as: "div", variant: "inherit", size: "xs", weight: "medium", className: "flex items-center gap-2", children: [
                "My Shoebox ",
                /* @__PURE__ */ e.jsx(O, { variant: "warning", className: "w-4 h-4 p-0 text-[8px] justify-center font-medium", children: m })
              ] })
            }
          ),
          /* @__PURE__ */ e.jsx(g.Provider, { children: /* @__PURE__ */ e.jsxs(g, { children: [
            /* @__PURE__ */ e.jsx(g.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx("button", { className: p("", {
              hidden: a === "small"
            }), children: /* @__PURE__ */ e.jsx(
              h,
              {
                strokeWidth: 1.5,
                name: "InfoEmpty",
                width: 14,
                height: 14
              }
            ) }) }),
            /* @__PURE__ */ e.jsxs(
              g.Content,
              {
                align: "start",
                side: "bottom",
                alignOffset: 0,
                className: "p-4 flex gap-3 z-dialog w-80",
                children: [
                  /* @__PURE__ */ e.jsx(y, { size: "xs", className: "rounded-md", children: /* @__PURE__ */ e.jsx(y.Fallback, { className: "p-0 bg-blue-100 text-white ", children: /* @__PURE__ */ e.jsx(h, { strokeWidth: 1.5, name: "Box", width: 16, height: 16 }) }) }),
                  /* @__PURE__ */ e.jsxs("div", { className: "flex flex-col gap-2", children: [
                    /* @__PURE__ */ e.jsx(n, { size: "sm", weight: "medium", children: "My Shoebox" }),
                    /* @__PURE__ */ e.jsx(n, { variant: "inherit", size: "sm", children: "Upload to your shoebox and select/drag to add items to your existing loan" })
                  ] })
                ]
              }
            )
          ] }) })
        ] }),
        /* @__PURE__ */ e.jsxs(
          "div",
          {
            ...w,
            className: p("p-2 flex group flex-col gap-2 items-center border-dashed border rounded-md relative overflow-hidden", {
              "aspect-square w-full flex items-center justify-center": a === "small"
            }),
            children: [
              /* @__PURE__ */ e.jsx("input", { ...d.getInputProps(), id: "shoebox-footer-dropdown-target-input" }),
              /* @__PURE__ */ e.jsx(
                h,
                {
                  className: p("", {
                    hidden: a === "default"
                  }),
                  name: "Plus",
                  width: 24,
                  height: 24
                }
              ),
              /* @__PURE__ */ e.jsx(
                n,
                {
                  variant: "inherit",
                  className: p("text-[10px]", {
                    hidden: a === "small"
                  }),
                  children: "Drag and Drop Files"
                }
              ),
              /* @__PURE__ */ e.jsx(
                n,
                {
                  variant: "inherit",
                  className: p("text-[10px]", {
                    hidden: a === "small"
                  }),
                  children: "or"
                }
              ),
              /* @__PURE__ */ e.jsx(
                n,
                {
                  as: "div",
                  onClick: (S) => {
                    S.stopPropagation(), S.preventDefault(), d.open();
                  },
                  variant: "inherit",
                  className: p("text-[10px] px-8 underline", {
                    hidden: a === "small"
                  }),
                  children: "Browse my device"
                }
              ),
              (d.isDragActive || s.isOver) && /* @__PURE__ */ e.jsx(
                n,
                {
                  size: "sm",
                  className: "absolute flex inset-0 bg-blue-10 text-blue-60  items-center justify-center transition-opacity",
                  children: "DROP FILES HERE"
                }
              )
            ]
          }
        )
      ]
    }
  );
});
Cs.displayName = "GlobalShoeBoxTriggerArea";
export {
  jl as GlobalShoeBoxProvider,
  Cs as GlobalShoeBoxTriggerArea,
  Es as GlobalShoeBoxUserActiveLoans,
  Ll as default
};
